import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAppState } from 'store/types'

const initialState: IAppState = {
  isLoading: false,
}

export const appSlice = createSlice({
  initialState,
  name: 'appSlice',
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export default appSlice.reducer

export const { setAppLoading } = appSlice.actions
