import { GenericListProps, LanguageProps, LanguageSelectedProps } from '@icr-ui/core'
import { isEmptyArray } from '@icr-ui/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store'
import { setAppLoading } from 'store/slices'

interface UseTranslateReturn {
  languageConfig: LanguageProps
}

const useTranslate = (): UseTranslateReturn => {
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const [languageItems, setLanguageItems] = useState<GenericListProps[]>([])
  const [lngs, setLngs] = useState({ de: { nativeName: 'Deutsch' } })
  const defaultLanguage = i18n.language.split('-')[0]
  const [selectedLanguage, setLanguage] = useState<LanguageSelectedProps>({
    label: defaultLanguage.toUpperCase(),
    value: `${defaultLanguage}-${defaultLanguage.toUpperCase()}`,
  })
  const getSelectedLanguage = (language: string) => {
    const newLanguage = languageItems.filter((languageItem: GenericListProps) =>
      languageItem.value?.toString().includes(language),
    )[0]
    return newLanguage
  }

  useEffect(() => {
    i18n.services.backendConnector.backend.getLanguages((err: any, ret: any) => {
      if (!err) setLngs(ret)
    })
  }, [])

  i18n.on('languageChanged', () => {
    dispatch(setAppLoading(false))
  })

  const handleChangeLanguage = async (language: GenericListProps) => {
    const newLanguage = getSelectedLanguage(language.value as string)
    if (newLanguage?.value) {
      setLanguage(newLanguage)
      await i18n.changeLanguage(newLanguage.value as string)
    }
    dispatch(setAppLoading(false))
  }

  useEffect(() => {
    if (!isEmptyArray(i18n.languages) && languageItems.length < 2) {
      const languageItems = Object.entries(lngs).map((info: any) => {
        const nativeName = info[0].split('-')[0].toUpperCase()
        return {
          label: nativeName,
          value: info[0],
          title: nativeName,
        }
      })
      setLanguageItems(languageItems)
    }
  }, [lngs, i18n.language])

  const languageConfig: LanguageProps = {
    languageList: languageItems,
    selectedLanguage,
    setSelectedLanguage: (selectedLanguage: LanguageSelectedProps) => {
      dispatch(setAppLoading(true))
      handleChangeLanguage(selectedLanguage)
    },
    hiddenMobile: true,
  }

  return { languageConfig }
}

export default useTranslate
