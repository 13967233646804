import { setAnswer, setQuestion, updateAnswer } from 'store/slices'
import { publicAPI } from './api'
import { defaultBody } from './constants'
import { IAiAssistantRequest, IAiAssistantResponse } from 'store/types'

export const aiAssistantApi = publicAPI.injectEndpoints({
  endpoints: builder => ({
    question: builder.mutation<IAiAssistantResponse, IAiAssistantRequest>({
      query({ data, tenant = 'PKSO' }) {
        return {
          url: `questions/${tenant}`,
          method: 'POST',
          body: defaultBody(data),
        }
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        dispatch(setQuestion(_args.data.prompt))
        dispatch(setAnswer())
        try {
          const {
            data: {
              data: { answer },
            },
          } = await queryFulfilled
          dispatch(updateAnswer(answer))
        } catch (error) {
          console.error(error)
        }
      },
    }),
  }),
})

export const { useQuestionMutation } = aiAssistantApi
