import { Home } from 'pages'
import { Route, Routes } from 'react-router-dom'
import { DefaultRoutes } from './routes'
import { LayoutWrapper } from 'components'

const Navigation = () => {
  return (
    <Routes>
      <Route path={DefaultRoutes.APP} element={<LayoutWrapper />}>
        <Route index element={<Home />} />
      </Route>
    </Routes>
  )
}

export default Navigation
