import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query'

const baseUrl = process.env.REACT_APP_SERVER_ENDPOINT

// used to login and get the bearer token
export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, appApi, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({ baseUrl })
  return rawBaseQuery(args, appApi, extraOptions)
}
