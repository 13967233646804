import { useTranslation } from 'react-i18next'

const useT = (ns?: string | string[]) => {
  const { t } = useTranslation(ns)

  const translate = (
    key: string,
    defaultValue: string,
    ns: string,
    values?: Record<string, string>,
  ) => t(key, { defaultValue, ns: [ns], ...values }) as string

  return { translate }
}

export default useT
