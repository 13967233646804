import { Container, Flexbox, styled } from '@icr-ui/core'

export const StyledChatSection = styled(Container)`
  width: 100% !important;
  padding: unset;
  margin: unset;
  padding-top: 60px;
  padding-bottom: 125px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding-top: 32px;
  }
`

export const StyledInputWrapper = styled(Container)`
  background-color: #edeff0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  padding: unset;
  margin: unset;
  padding: 30px 300px 0px 300px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding: 30px 20px 0 20px;
  }

  @media (min-width: 1280px) {
    max-width: unset;
  }

  [data-testid='text-field-input'] {
    padding-right: 25px;
  }
`

const StyledChatRowDefault = styled(Container)`
  display: flex;
  max-width: unset !important;
  width: 100%;
  margin: unset;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 300px;
  gap: 24px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`

const StyledAvatar = styled(Flexbox)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 48px;
  padding: 0px;
  border-radius: 4px;
  width: 48px;
  height: 48px;
`

export const StyledAvatarQuestion = styled(StyledAvatar)`
  background: #576066;
`

export const StyledAvatarAnswer = styled(StyledAvatar)`
  background: #8b9399;
`

export const StyledConversation = styled(Flexbox)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`

export const StyledQuestion = styled(StyledChatRowDefault)`
  background-color: transparent;
`

export const StyledAnswer = styled(StyledChatRowDefault)`
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #d0d5d9;
`
