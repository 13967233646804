export interface IAiAssistantRequest {
  data: {
    prompt: string
    conversation: string
  }
  tenant: string
}

export interface IAiAssistantResponse {
  data: {
    answer: string
    context: string
    jsonapi: {
      version: string
    }
  }
}

export interface IAiAssistantState {
  conversation: IConversation[]
}

export interface IConversation {
  prompt: string
  type: IConversationType
}

export enum IConversationType {
  QUESTION = 'question',
  ANSWER = 'answer',
}
