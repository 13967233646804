import { useT, useTranslate } from 'locale'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { StyledLayoutWrapper } from './styled'
import { Layout } from '@icr-ui/core'

const LayoutWrapper: FC = () => {
  //   const { userState: user } = useAppSelector(state => state)
  const { languageConfig } = useTranslate()
  const { translate } = useT(['login', 'user-list', 'common', 'app'])

  return (
    <StyledLayoutWrapper fullScreen noPadding>
      <Layout
        className="chat-layout-wrapper"
        headerProps={{
          title: 'PD Chatbot',
          languageProps: languageConfig,
          userProfileProps: {
            accountProps: {
              name: 'Thomas Meier',
              email: 'thomas.meier@pensiondynamics.com.br',
              logoutButtonAction: () => console.log('logout'),
              profileActions: [
                {
                  label: translate('profile.link.userProfile', 'Profil', 'app'),
                  onClick: () => console.log('user profile'),
                },
                {
                  label: translate(
                    'profile.link.termsOfUse',
                    'Nutzungsbedingungen',
                    'app',
                  ),
                  onClick: () =>
                    window.open(
                      'https://resources.pensiondynamics.ch/pkso/Nutzungsbedingungen.pdf',
                      '_blank',
                      'norefereer noopeneer',
                    ),
                },
              ],
            },
            languageProps: languageConfig,
          },
        }}
        contentStyles={{
          justifyContent: 'space-between',
        }}
      >
        <Outlet />
      </Layout>
    </StyledLayoutWrapper>
  )
}

export default LayoutWrapper
