import { ThemeProvider } from '@emotion/react'
import { CustomToastContainer, GlobalStyles, Loader } from '@icr-ui/core'
import { defaultTheme as theme } from '@icr-ui/theme'
import { Suspense } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Navigation from 'routes'

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>PD AI Assistant</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href={theme.fonts.defaultFontFamily} rel="stylesheet" />
        <meta name="theme-color" content={theme.colors.primary.main} />
      </Helmet>
      <Suspense fallback={<Loader />}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <CustomToastContainer />
          <Navigation />
        </ThemeProvider>
      </Suspense>
    </HelmetProvider>
  )
}

export default App
