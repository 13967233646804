import { TextField } from '@icr-ui/core'
import {
  StyledAnswer,
  StyledAvatarAnswer,
  StyledAvatarQuestion,
  StyledChatSection,
  StyledConversation,
  StyledInputWrapper,
  StyledQuestion,
} from './styled'
import * as Icons from '@icr-ui/icons'
import { useQuestionMutation } from 'store/api'
import { useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'store'

const Home = () => {
  const {
    aiAssistantState: { conversation },
  } = useAppSelector(state => state)
  const [questionState, setQuestionState] = useState<string>('')
  const [question, { isLoading }] = useQuestionMutation()

  const isLastAnswer = (index: number) => {
    return index === conversation.length - 1
  }

  const submit = async () => {
    await question({
      data: {
        conversation: buildConversationPayload(),
        prompt: inputRef.current?.firstElementChild?.getAttribute('value') as string,
      },
      tenant: 'PKSO',
    })
    setQuestionState('')
  }

  const buildConversationPayload = () => {
    return conversation.map(item => `${item.prompt}\n`).join('')
  }

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // listen to enter key and submit
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        submit()
      }
    }

    if (inputRef.current) {
      inputRef.current.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [inputRef])

  const chatRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [conversation])

  return (
    <>
      <StyledChatSection ref={chatRef}>
        {conversation.map((item, index) => {
          if (item.type === 'question') {
            return (
              <StyledQuestion key={index}>
                <StyledAvatarQuestion>
                  <Icons.EmployerIcon width={24} height={24} color="white" />
                </StyledAvatarQuestion>
                <StyledConversation>{item.prompt}</StyledConversation>
              </StyledQuestion>
            )
          } else {
            return (
              <StyledAnswer key={index}>
                <StyledAvatarAnswer>
                  <Icons.AtomIcon width={24} height={24} color="white" />
                </StyledAvatarAnswer>
                {isLoading && isLastAnswer(index) && <Icons.LoaderIcon />}
                <StyledConversation>{item.prompt}</StyledConversation>
              </StyledAnswer>
            )
          }
        })}
      </StyledChatSection>

      <StyledInputWrapper>
        <TextField
          ref={inputRef}
          id={'searchId'}
          customStyles={{ backgroundColor: 'transparent' }}
          type="text"
          customIcon={<Icons.ArrowRightIcon />}
          onChange={e => setQuestionState(e.target.value)}
          iconClickAction={() => submit()}
          placeholder="Send a question..."
          name={'search'}
          value={questionState}
        />
      </StyledInputWrapper>
    </>
  )
}

export default Home
