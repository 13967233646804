import { Flexbox, styled } from '@icr-ui/core'

export const StyledLayoutWrapper = styled(Flexbox)`
  [aria-label='layout-content'] {
    padding: unset;
  }

  @media (max-width: 883px) {
    & div[aria-label='layout-wrapper'] {
      padding: 6.45rem 0 0 0;
    }
  }
`
