import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAiAssistantState, IConversationType } from 'store/types'

const initialState: IAiAssistantState = {
  conversation: [],
}

export const aiAssistantSlice = createSlice({
  initialState,
  name: 'aiAssistantSlice',
  reducers: {
    resetConversation: () => initialState,
    setAnswer: state => {
      state.conversation.push({ prompt: '', type: IConversationType.ANSWER })
    },
    updateAnswer: (state, action: PayloadAction<string>) => {
      const lastAnswer = state.conversation[state.conversation.length - 1]
      state.conversation[state.conversation.length - 1] = {
        ...lastAnswer,
        prompt: action.payload,
      }
    },
    setQuestion: (state, action: PayloadAction<string>) => {
      state.conversation.push({
        prompt: action.payload,
        type: IConversationType.QUESTION,
      })
    },
  },
})

export default aiAssistantSlice.reducer

export const { resetConversation, setAnswer, setQuestion, updateAnswer } =
  aiAssistantSlice.actions
